<template>
  <div>
    <h2 class="font-22-700 dark-text mb-5 text-center">Contact Information</h2>
    <base-input
      class="input-group-alternative"
      placeholder="Company LTD"
      type="text"
      v-model="fields.name"
      :error="errors.name"
    >
    </base-input>
    <base-input
      class="input-group-alternative"
      placeholder="Principle Contact Name or Сompany Owner"
      type="text"
      v-model="fields.principleContact"
    >
    </base-input>

    <base-input
      class="input-group-alternative"
      placeholder="Email Address"
      type="text"
      v-model="fields.email"
      :error="errors.email"
      disabled
    >
    </base-input>

    <base-input
      class="input-group-alternative"
      placeholder="Company Address Line 1"
      type="text"
      v-model="fields.address"
    >
    </base-input>
    <base-input
      class="input-group-alternative"
      placeholder="Company Address Line 2"
      type="text"
      v-model="fields.address2"
    >
    </base-input>
    <div class="d-flex">
      <base-input
        class="input-group-alternative pr-1"
        placeholder="City"
        type="text"
        v-model="fields.city"
        :error="errors.city"
      >
      </base-input>
      <div class="input-group-alternative pr-1">
        <select class="state-select pr-1" v-model="fields.state">
          <option style="color: #7b809d" value="" disabled selected>
            Select State
          </option>
          <option
            v-for="state in stateOptions.map((state) => state)"
            :key="state.abbreviation"
            v-bind:value="state.abbreviation"
          >
            {{ state.name }}
          </option>
        </select>
        <form-error nonInput v-show="errors.state">
          {{ errors.state }}
        </form-error>
      </div>
      <base-input
        class="input-group-alternative"
        placeholder="Zip Code"
        type="text"
        v-model="fields.zip"
        :error="errors.zip"
      >
      </base-input>
    </div>

    <phone-input
      inputClasses="phone-input"
      placeholder="Phone number"
      :border-radius="14"
      :error="errors.phoneNumber"
      :value="fields.phoneNumber"
      @on-change="fields.phoneNumber = $event"
    />

    <base-input
      class="input-group-alternative"
      placeholder="Website"
      type="text"
      v-model="fields.website"
      :error="errors.website"
    >
    </base-input>
  </div>
</template>

<script>
import { EventBus } from "@/utils/event-bus"
import {
  validateEmail,
  validateUrl,
  validateUsPhoneNumber,
  validateZipCode,
  deNullifyErrors,
} from "@/utils/validations"
import options from "@/utils/options"
export default {
  name: "company-profile-contact-information",
  props: {
    fields: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      errors: {},
      stateOptions: options.stateOptions,
    }
  },
  mounted() {
    EventBus.$on("validate-company-contact-info", () => {
      this.validateContactInfo()
    })
  },
  beforeDestroy() {
    // removing eventBus listener
    EventBus.$off("validate-company-contact-info")
  },
  methods: {
    validateContactInfo() {
      let errors = {
        email: !this.fields.email
          ? "Please enter your email address"
          : !validateEmail(this.fields.email)
          ? "Please enter a valid email address"
          : null,
        phoneNumber: !validateUsPhoneNumber(this.fields.phoneNumber)
          ? "Please enter a valid phone number"
          : null,
        website:
          this.fields.website && !validateUrl(this.fields.website)
            ? "Please enter a valid web address"
            : null,
        name: !this.fields.name ? "Please enter your company name" : null,
        state: !this.fields.state ? "Required" : null,
        city: !this.fields.city ? "Required" : null,
        zip: !this.fields.zip
          ? "Please enter your company ZIP code"
          : !validateZipCode(this.fields.zip)
          ? "Please enter a valid ZIP code"
          : null,
      }
      this.errors = errors
      this.$emit(
        "proceed-next",
        Object.keys(deNullifyErrors(errors)).length > 0 ? false : true
      )
    },
  },
}
</script>

<style lang="scss" scoped>
.state-select {
  border: 0;
  box-shadow: none;
  background: #ffffff;
  color: #7b809d;
  border: 2px solid rgba(0, 48, 73, 0.2);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 14px;
  height: 50px;
  line-height: 50px;
  padding: 0 1rem;
  color: #003049;
  font-weight: 500;
  max-width: 150px;
}
</style>
