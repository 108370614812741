<template>
  <div>
    <b-form-group :label="'Industry of work performed or served'">
      <b-form-checkbox-group
        class="gfb-radio-container"
        id="checkbox-group-1"
        v-model="fields.industry"
        :options="industryOptions"
        value-field="id"
        text-field="name"
      ></b-form-checkbox-group>
    </b-form-group>

    <b-form-group label="What is your EMR?">
      <input
        class="form-control emr-input"
        type="number"
        v-model="fields.emr"
        placeholder="1.50"
        step="0.01"
        min="0.00"
        max="6.00"
      />
    </b-form-group>

    <b-form-group
      :label="'Do you hold an OSHA certification?'"
      class="gfb-radio-container"
    >
      <b-form-radio-group
        v-model="fields.oshaCertification"
        :options="oshaCertificationOptions"
        ><b-form-invalid-feedback :state="!errors.oshaCertification">{{
          errors.oshaCertification
        }}</b-form-invalid-feedback></b-form-radio-group
      >
    </b-form-group>

    <b-form-group label="Is your company licensed?" class="gfb-radio-container">
      <b-form-radio-group
        v-model="fields.provider.licensed"
        :options="trueFalse"
      ></b-form-radio-group>
    </b-form-group>
    <base-input
      v-if="fields.provider.licensed"
      class="input-group-alternative"
      placeholder="License Type"
      type="text"
      v-model="fields.provider.licenseType"
      :error="errors.provider && errors.provider.licenseType"
    >
    </base-input>
    <base-input
      v-if="fields.provider.licensed"
      class="input-group-alternative"
      placeholder="License Number"
      type="text"
      v-model="fields.provider.licenseNumber"
      :error="errors.provider && errors.provider.licenseNumber"
    >
    </base-input>

    <b-form-group
      label="Does your company carry the required minimums for General Liability and Worker’s Compensation insurance?"
      class="gfb-radio-container"
    >
      <b-form-radio-group
        v-model="fields.carryWorkersCompGeneralLiableMin"
        :options="yesNo"
      ></b-form-radio-group>
    </b-form-group>

    <b-form-group
      label="Please list any industry organizations or 
        affiliations you are a member of"
    >
      <CustomMultiSelect
        :selectOptions="fields.affiliations"
        :selectedList="fields.affiliations"
        selectLabel="Add affiliation"
        :tagFlag="true"
        @handle-tag="handleTag"
        @remove-option="removeAffiliation"
        @select-option="selectAffiliation"
      ></CustomMultiSelect>
    </b-form-group>

    <b-form-group
      label="Briefly describe your company, your mission, specialized work"
    >
      <small class="pr-5 mb-3 d-block"
        >And the specific services you provide to the construction/skilled
        trades industry in detail</small
      >
      <b-form-textarea
        v-model="fields.description"
        placeholder="Description"
        rows="6"
        max="250"
      ></b-form-textarea>
    </b-form-group>
  </div>
</template>

<script>
import { EventBus } from "@/utils/event-bus"
import options from "@/utils/options"
import { deNullifyErrors } from "@/utils/validations"
import CustomMultiSelect from "@/components/CustomMultiSelect.vue"

export default {
  name: "company-profile-edit-additional",
  components: { CustomMultiSelect },
  props: {
    fields: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    EventBus.$on("validate-company-additional", () => {
      this.validate()
    })
  },
  beforeDestroy() {
    // removing eventBus listener
    EventBus.$off("validate-company-additional")
  },
  data() {
    return {
      errors: {},
      trueFalse: options.trueFalse,
      yesNo: options.yesNo,
      oshaCertificationOptions: options.oshaCertificationOptions,
      industryOptions: options.industryOptions,
    }
  },
  methods: {
    handleTag(newTag) {
      this.fields.affiliations = this.fields.affiliations.concat(newTag)
    },
    selectAffiliation(option) {
      this.affiliations.push(option)
    },
    removeAffiliation(option) {
      this.fields.affiliations = this.fields.affiliations.filter(
        (affiliation) => affiliation !== option
      )
    },
    validate() {
      const oshaCertification = this.fields.oshaCertification
      let errors = {
        oshaCertification: !oshaCertification ? "Required" : null,
      }
      this.errors = errors
      this.$emit(
        "proceed-next",
        Object.keys(deNullifyErrors(errors)).length > 0 ? false : true
      )
    },
  },
}
</script>

<style lang="scss" scoped>
.emr-input {
  width: 100px;
}
</style>
