<template>
  <div>
    <b-form-group
      label="What type of position are you seeking?"
      v-slot="{ ariaDescribedby }"
    >
      <label for="">Select all that apply</label>
      <b-form-checkbox-group
        class="gfb-radio-container"
        id="checkbox-group-1"
        v-model="fields.jobSeeker.positionSeeking"
        :options="positionOptions"
        :aria-describedby="ariaDescribedby"
      ></b-form-checkbox-group>
      <form-error nonInput v-show="errors.positionSeeking">{{
        errors.positionSeeking
      }}</form-error>
    </b-form-group>

    <b-form-group
      label="Do you have project or jobsite management experience?"
      v-slot="{ ariaDescribedby }"
      class="gfb-radio-container"
    >
      <b-form-radio-group
        v-model="fields.jobSeeker.hasManagementExperience"
        :options="trueFalse"
        :aria-describedby="ariaDescribedby"
      ></b-form-radio-group>
      <form-error nonInput v-show="errors.hasManagementExperience">{{
        errors.hasManagementExperience
      }}</form-error>
    </b-form-group>

    <div v-if="fields.jobSeeker.hasManagementExperience" class="mb-5">
      <b-form-group label="Select all that apply to your management experience">
        <CustomMultiSelect
          :selectOptions="managementExperienceOptions"
          :selectedList="fields.jobSeeker.managementExperienceCategories"
          selectLabel="Search or select experience"
          @remove-option="removeManagementExperience"
          @select-option="selectManagementExperience"
        ></CustomMultiSelect>
      </b-form-group>
    </div>

    <b-form-group
      label="Do you have any hands-on experience working in the skilled trades/construction industry?"
      v-slot="{ ariaDescribedby }"
      class="gfb-radio-container"
    >
      <b-form-radio-group
        v-model="fields.jobSeeker.hasHandsOnExperience"
        :options="trueFalse"
        :aria-describedby="ariaDescribedby"
      ></b-form-radio-group>
      <form-error nonInput v-show="errors.hasHandsOnExperience">{{
        errors.hasHandsOnExperience
      }}</form-error>
    </b-form-group>

    <!-- If No -->
    <div v-if="fields.jobSeeker.hasHandsOnExperience === false" class="mb-5">
      <b-form-group
        label="Please select any trade categories that you are interested in finding
          paid training opportunities"
      >
        <CustomMultiSelect
          :selectOptions="categoryOptions"
          :selectedList="fields.jobSeeker.handsOnExperiencesTrainings"
          selectLabel="Search or select category"
          @remove-option="removeTraining"
          @select-option="selectTraining"
        ></CustomMultiSelect>
      </b-form-group>
    </div>

    <!-- If Yes -->
    <div v-if="fields.jobSeeker.hasHandsOnExperience">
      <b-form-group label="Select all that apply to your hands on experience">
        <CustomMultiSelect
          :selectOptions="categoryOptions"
          :selectedList="handsOnExperienceCategories"
          selectLabel="Search or select category"
          @remove-option="removeExperience"
          @select-option="selectExperience"
        ></CustomMultiSelect>
      </b-form-group>

      <div class="accordion my-5" role="tablist">
        <b-card
          no-body
          class="mb-4 experience-card"
          v-for="(experience, index) in fields.jobSeeker.handsOnExperiences"
          :key="experience.category"
        >
          <div class="bg-primary py-2 text-center text-white category-heading">
            {{ experience.category }}
          </div>

          <b-card-body>
            <b-form-group
              label="How much experience do you have in this trade category?"
              v-slot="{ ariaDescribedby }"
              class="gfb-radio-container"
            >
              <b-form-radio-group
                v-model="fields.jobSeeker.handsOnExperiences[index].amount"
                :options="howMuchExperienceOptions"
                :aria-describedby="ariaDescribedby"
              ></b-form-radio-group>
              <form-error
                nonInput
                v-show="
                  errors.handsOnExperiences &&
                  errors.handsOnExperiences[index] &&
                  errors.handsOnExperiences[index].amount
                "
                >{{
                  errors.handsOnExperiences &&
                  errors.handsOnExperiences[index] &&
                  errors.handsOnExperiences[index].amount
                }}</form-error
              >
            </b-form-group>
            <b-form-group label="Please rate your experience in this category">
              <ExperienceSliderContainer
                :rating="fields.jobSeeker.handsOnExperiences[index].rating"
                @on-change="(rating) => updateRating(index, rating)"
              />
              <form-error
                nonInput
                v-show="
                  errors.handsOnExperiences &&
                  errors.handsOnExperiences[index] &&
                  errors.handsOnExperiences[index].rating
                "
                >{{
                  errors.handsOnExperiences &&
                  errors.handsOnExperiences[index] &&
                  errors.handsOnExperiences[index].rating
                }}</form-error
              >
            </b-form-group>
          </b-card-body>
        </b-card>
      </div>
    </div>
    <b-form-group
      label="Do you currently have an active Coalition of Construction Safety (CCS) card?"
      v-slot="{ ariaDescribedby }"
      class="gfb-radio-container"
    >
      <b-form-radio-group
        v-model="fields.jobSeeker.hasCcsCard"
        :options="trueFalse"
        :aria-describedby="ariaDescribedby"
      ></b-form-radio-group>
    </b-form-group>

    <b-form-group
      label="Do you hold an OSHA certification?"
      v-slot="{ ariaDescribedby }"
      class="gfb-radio-container"
    >
      <b-form-radio-group
        v-model="fields.jobSeeker.oshaCertification"
        :options="oshaCertificationOptions"
        :aria-describedby="ariaDescribedby"
      ></b-form-radio-group>
      <form-error nonInput v-show="errors.oshaCertification">{{
        errors.oshaCertification
      }}</form-error>
    </b-form-group>

    <b-form-group
      label="List any additional certifications, licenses, skills, or training you
        possess that will make you valuable to employers"
    >
      <b-form-textarea
        v-model="fields.jobSeeker.idealJobDescription"
        placeholder="Description"
        rows="6"
      ></b-form-textarea>
    </b-form-group>
  </div>
</template>

<script>
import { EventBus } from "@/utils/event-bus"
import { deNullifyErrors, normalize, requiredError } from "@/utils/validations"
import options from "@/utils/options"
import CustomMultiSelect from "@/components/CustomMultiSelect"
import ExperienceSliderContainer from "@/components/ExperienceSliderContainer"

export default {
  name: "candidate-profile-business-information",
  components: {
    CustomMultiSelect,
    ExperienceSliderContainer,
  },
  props: {
    fields: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      errors: {},
      trueFalse: options.trueFalse,
      positionOptions: options.positionOptions,
      oshaCertificationOptions: options.oshaCertificationOptions,
      ratingOptions: options.ratingOptions,
      howMuchExperienceOptions: options.howMuchExperienceOptions,
      categoryOptions: options.categoryOptions,
      filteredOptions: options.filteredOptions,
      managementExperienceOptions: options.managementExperienceOptions,
    }
  },
  mounted() {
    EventBus.$on("validate-candidate-business-info", () => {
      this.validate()
    })
  },
  beforeDestroy() {
    // removing eventBus listener
    EventBus.$off("validate-candidate-business-info")
  },
  methods: {
    selectTraining(option) {
      return this.fields.jobSeeker.handsOnExperiencesTrainings.push(option)
    },
    removeTraining(option) {
      this.fields.jobSeeker.handsOnExperiencesTrainings =
        this.fields.jobSeeker.handsOnExperiencesTrainings.filter(
          (training) => training !== option
        )
    },
    selectExperience(option) {
      let experiences = [...this.fields.jobSeeker.handsOnExperiences]
      experiences.push({
        category: option,
        amount: "",
      })
      this.fields.jobSeeker.handsOnExperiences = [...experiences]
    },
    removeExperience(option) {
      this.fields.jobSeeker.handsOnExperiences =
        this.fields.jobSeeker.handsOnExperiences.filter(
          (experience) => experience.category !== option
        )
    },
    selectManagementExperience(option) {
      return this.fields.jobSeeker.managementExperienceCategories.push(option)
    },
    removeManagementExperience(option) {
      this.fields.jobSeeker.managementExperienceCategories =
        this.fields.jobSeeker.managementExperienceCategories.filter(
          (experience) => experience !== option
        )
    },
    updateRating(index, rating) {
      let experiences = [...this.fields.jobSeeker.handsOnExperiences]
      experiences[index].rating = rating
      this.fields.jobSeeker.handsOnExperiences = [...experiences]
    },
    validate() {
      const data = normalize(this.fields)
      const { jobSeeker } = data
      const {
        positionSeeking,
        hasManagementExperience,
        hasHandsOnExperience,
        handsOnExperiences,
      } = jobSeeker ?? {}

      const handsOnExperiencesErrors = !handsOnExperiences
        ? null
        : handsOnExperiences.reduce((errors, experience) => {
            const { category, amount, rating } = experience
            const experienceError = {
              category: requiredError(category),
              amount: requiredError(amount),
              rating: requiredError(rating),
            }
            return [
              ...errors,
              Object.keys(normalize(experienceError)).length > 0
                ? experienceError
                : null,
            ]
          }, [])
      let errors = {
        positionSeeking: requiredError(positionSeeking),
        hasManagementExperience: requiredError(hasManagementExperience),
        hasHandsOnExperience: requiredError(hasHandsOnExperience),
        handsOnExperiences:
          hasHandsOnExperience &&
          handsOnExperiencesErrors?.filter(Boolean).length > 0
            ? handsOnExperiencesErrors
            : null,
      }
      this.errors = errors
      this.$emit(
        "proceed-next",
        Object.keys(deNullifyErrors(errors)).length > 0 ? false : true
      )
    },
  },
  computed: {
    handsOnExperienceCategories() {
      return this.fields.jobSeeker.handsOnExperiences.map(
        (experience) => experience.category
      )
    },
  },
}
</script>

<style lang="scss" scoped>
.experience-card {
  padding: 0;
  border-radius: 12px !important;

  .category-heading,
  .card-body {
    width: 100%;
  }
}
</style>
